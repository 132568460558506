import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from "rxjs/internal/operators/first";
import { Router } from "@angular/router";
import { User } from '../_models/user';
import Swal from 'sweetalert2'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject!: BehaviorSubject<User>;
  public currentUser!: Observable<User>;

  env = environment;

  constructor(private http: HttpClient,
    private router: Router) {
    let userData = localStorage.getItem('currentUser');
    if (userData) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(userData));
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject?.value;
  }

  login(username: any, password: any) {
    return this.http.post<any>(this.env.API_URL + `auth/login`, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);

        //devo rinfrescare i ruoli
        this.getWhoami(user.token).subscribe(result => {

          if (result.status === 'ACTIVE') {
            user.operator = result;
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            //va tutto bene, continuiamo
          }
          if (result.status === 'DEACTIVE') {
            Swal.fire({
              title: 'Mi spiace',
              text: 'Utente non attivo',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            this.logout();
            this.router.navigate(['/login'], { queryParams: { status: result.status.toLowerCase() } });
          }
          if (result.status === 'PENDING') {
            Swal.fire({
              title: 'Mi spiace',
              text: 'Utente in stato pending',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            this.logout();
            this.router.navigate(['/login'], { queryParams: { status: result.status.toLowerCase() } });

          }

        });

        return user;
      }));
  }


  // HttpClient API get() method => Whoami
  getWhoami(token: string) {
    return this.http.get<any>(this.env.API_URL + 'auth/whoami',{ headers: {
      Authorization: `Bearer ${token}`
    }});
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null as any);
  }

}


