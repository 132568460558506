import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { PermissionService } from '../_services';
import { User } from '../_models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  content?: string;

  currentUser: User | undefined;

  userSubscription: Subscription | undefined;

  constructor(private userService: UserService,
    private router: Router,
    public permissionService: PermissionService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.userSubscription = this.authenticationService.currentUser?.subscribe(x => {
      this.currentUser = x;
    });

  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }


}
