import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Risorsa } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class RisorseService {

  constructor(private http: HttpClient) { }

  env = environment;

  // HttpClient API get() method => Search
  search(idEnte: number | undefined, anno: number | undefined, pageNum: number, pageSize: number, filter: any, sort: any, interno: string) {
    if (interno==="tutti") {
      return this.http.get<any>(this.env.API_URL + 'risorsa_umana/search?idEnte=' +idEnte+ '&anno='+anno+'&page=' + pageNum + '&size=' + pageSize + (filter ? '&cognome=' + encodeURI(filter) : '') + ((sort !== '' && sort !== undefined) ? '&sort=' + encodeURI(sort) : ''));
    }
    return this.http.get<any>(this.env.API_URL + 'risorsa_umana/search?interno='+(interno==='interni')+'&idEnte=' +idEnte+ '&anno='+anno+'&page=' + pageNum + '&size=' + pageSize + (filter ? '&cognome=' + encodeURI(filter) : '') + ((sort !== '' && sort !== undefined) ? '&sort=' + encodeURI(sort) : ''));

  }

  // HttpClient API get() method => Risorsa read
  getRisorsa(idRisorsa: number) {
    return this.http.get<any>(this.env.API_URL + 'risorsa_umana/' + idRisorsa);
  }

  getCategorieList(idEnte: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'risorsa_umana/categorie?idEnte=' + idEnte);
  }

  getProfiliList(idEnte: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'risorsa_umana/profili?idEnte=' + idEnte);
  }

  getContrattiList(idEnte: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'risorsa_umana/contratti?idEnte=' + idEnte);
  }

  // HttpClient API post() method => Risorsa create
  setRisorsa(idEnte: number | undefined, anno: number | undefined, data: any) {
    const dataCompleta = {idEnte, anno, ...data};
    return this.http.post<any>(this.env.API_URL + 'risorsa_umana', dataCompleta);
  }


  // HttpClient API put() method => Risorsa update
  updateRisorsa(idEnte: number | undefined, anno: number | undefined, idRisorsa: number, data: any) {
    const dataCompleta = {idEnte, anno, ...data};
    return this.http.put<any>(this.env.API_URL + 'risorsa_umana/' + encodeURIComponent(idRisorsa), dataCompleta);
  }
  // HttpClient API delete() method => Risorsa delete
  deleteRisorsa(idRisorsa: number) {
    return this.http.delete<any>(this.env.API_URL + 'risorsa_umana/' + encodeURIComponent(idRisorsa));
  }

}
