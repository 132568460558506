import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models';
import { Subject, Observable } from "rxjs";
import { StruttureService } from './strutture.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AlberoService {

  constructor(private struttureService: StruttureService) {
    this.initializeClosed();
  }

  private subject = new Subject<any>();

  closed: any | undefined;

  currentStruttura: number | undefined;

  currentLevel: number | undefined = 0;

  //struttura albero a partire dalla radice
  struttura: any | undefined;

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  initializeClosed() {
    this.closed = {};
  }

  caricaStrutturaBase(currentUser: User | undefined, search: string ="") {
    this.currentLevel=0;
    this.struttureService.root(currentUser?.operator?.idEnte,
      currentUser?.operator?.anno, search).subscribe(result => {
      this.struttura = result;
      this.currentStruttura = result.id;
      for (let i in result.children) {
        //se i children hanno già i children li dò aperti
        //succede nella ricerca quando mi arrivano tutti
        //altrimenti li dò chiusi
        if (result.children[i].children) {
          this.closed[result.children[i].id] = false;
        } else {
          this.closed[result.children[i].id] = true;
        }
      }
      this.subject.next({ struttura: this.currentStruttura });

    });

  }




  updateStruttura(struttura: number) {
    this.subject.next({ struttura: struttura });
  }


}

