import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    env=environment;

    getAll() {
        return this.http.get<User[]>(this.env.API_URL+`/users`);
    }

    register(user: User) {
        return this.http.post(this.env.API_URL+`/users/register`, user);
    }

    delete(id: number) {
        return this.http.delete(this.env.API_URL+`/users/${id}`);
    }
}
