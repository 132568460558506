import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models';

enum Role {
  Amministratore = 'AMMINISTRATORE',
  GestioneRisorse = 'GESTIONE_RISORSE',
  GestoreStrutture = 'UPDATE_UO',
  GestoreArea = 'READ_ALL_UPDATE_UO',
  Risorsa = 'RISORSA',
  Audit = 'READ_ALL'
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient) { }

  env = environment;

  isAdmin(user: User | undefined) {
    return (user?.operator?.role===Role.Amministratore);
  }

  annoCorrente(user: User | undefined) {
    return (user?.operator?.anno);
  }

  isAnno(user: User,anno: number) {
    return (user?.operator?.anno===anno);
  }

  cambiaAnno ( anno: number, user: User | undefined) {
    if (user && user.operator) {
      user.operator.anno = anno;
      localStorage.setItem('currentUser', JSON.stringify(user));
    }
  }


}



