import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtentiService {

  constructor(private http: HttpClient) { }

  env = environment;

  // HttpClient API get() method => Search
  search(idEnte: number | undefined, anno: number | undefined, pageNum: number, pageSize: number, filter: string, sort: string) {
    return this.http.get<any>(this.env.API_URL + 'utente/search?idEnte=' +idEnte+ '&anno='+anno+'&page=' + pageNum + '&size=' + pageSize + (filter ? '&' + ('nome='+encodeURI(filter)) : '' ) + ((sort !== '' && sort !== undefined) ? '&sort=' + encodeURI(sort) : '' ) );
  }

  // HttpClient API get() method => Utente read
  getUtente(idUtente: string) {
    return this.http.get<any>(this.env.API_URL + 'utente/' + idUtente);
  }

  getRoleList() {
    return this.http.get<any>(this.env.API_URL + 'utente/ruoli');
  }

  getStruttureList() {
    return this.http.get<any>(this.env.API_URL + 'utente/strutture');
  }

  getRisorseList() {
    return this.http.get<any>(this.env.API_URL + 'utente/risorse');
  }

  // HttpClient API post() method => Utente create
  setUtente(data: any) {
     return this.http.post<any>(this.env.API_URL + 'utente', data);
  }

  // HttpClient API post() method => Utente activate
  setActivate(idUtente: number) {
    return this.http.post<any>(this.env.API_URL + 'utente/activate/' + encodeURIComponent(idUtente), null);
  }

  // HttpClient API post() method => Utente deactivate
  setDeactivate(idUtente: number) {
    return this.http.post<any>(this.env.API_URL + 'utente/deactivate/' + encodeURIComponent(idUtente), null);
  }

  // HttpClient API put() method => Utente update
  updateUtente(idUtente: number, data: any) {
    return this.http.put<any>(this.env.API_URL + 'utente/' + encodeURIComponent(idUtente), data);
  }


  // HttpClient API delete() method => Utente delete
  deleteUtente(idUtente: number) {
    return this.http.delete<any>(this.env.API_URL + 'utente/' + encodeURIComponent(idUtente));
  }

}
