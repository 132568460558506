
  <div class="content">
    <div class="breadcrumb">{{'home'|translate}}</div>
    <div class="title">{{'dashboard'|translate}}</div>
    <br><br><br>
    <div class="container">
      <div class="row g-5">

        <div class="col-4" *ngIf="permissionService.isAdmin(currentUser)">
          <a [routerLink]="['/sistema/utenti']" class="box">
            <div class="box-title">
              {{"sistema"|translate}}<br><br></div>
            <div class="d-flex align-items-center">
              <div class="box-image"><img src="/assets/img/gear.png"></div>
              <div class="box-text">{{"sistema descrizione"|translate}}</div>
              <div class="box-arrow">
                <i-bs name="arrow-right" width="1.5rem"
                height="1.5rem"></i-bs>

              </div>
            </div>
          </a>
        </div>

        <div class="col-4">
          <a [routerLink]="['/struttura']" class="box">
            <div class="box-title">
              {{"struttura organizzativa"|translate}}<br><br></div>
            <div class="d-flex align-items-center">
              <div class="box-image"><img src="/assets/img/hierarchical-structure.png"></div>
              <div class="box-text">{{"struttura organizzativa descrizione"|translate}}</div>
              <div class="box-arrow">
                <i-bs name="arrow-right" width="1.5rem"
                height="1.5rem"></i-bs>

              </div>
            </div>
          </a>
        </div>

        <div class="col-4">
          <a [routerLink]="['/programmazione']" class="box">
            <div class="box-title">
              {{"programmazione"|translate}}<br><br></div>
            <div class="d-flex align-items-center">
              <div class="box-image"><img src="/assets/img/calculator.png"></div>
              <div class="box-text">{{"programmazione descrizione"|translate}}</div>
              <div class="box-arrow">
                <i-bs name="arrow-right" width="1.5rem"
                height="1.5rem"></i-bs>

              </div>
            </div>
          </a>
        </div>



      </div>
    </div>
  </div><!--content-->

