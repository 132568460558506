import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Risorsa } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class StruttureService {

  constructor(private http: HttpClient) { }

  env = environment;

  albero(idEnte: number | undefined, anno: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/albero?idEnte='+idEnte+'&anno='+anno);
  }

  getStruttura(idStruttura: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/' + idStruttura);
  }

  root(idEnte: number | undefined, anno: number | undefined, filter: string | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/root?idEnte='+idEnte+'&anno='+anno+(filter?'&filter='+filter:''));
  }


  getRisorseAssociate(idStruttura: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/getRisorseAssociate/?idOrganizzazione=' + idStruttura);
  }

  getRisorseAssociabili(idStruttura: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/getRisorseAssociabili/?idOrganizzazione=' + idStruttura);
  }

  preparaStruttura(idPadre: number | undefined) {
    return this.http.get<any>(this.env.API_URL + 'struttura/prepareNuovaStruttura/?idPadre=' + idPadre);
  }

  getChildren(idStruttura: number) {
    return this.http.get<any>(this.env.API_URL + 'struttura/children/' + idStruttura);
  }

  getLivelloList(idEnte: number) {
    return this.http.get<any>(this.env.API_URL + 'struttura/livello?idEnte='+idEnte);
  }


  setStruttura(data: any) {
     return this.http.post<any>(this.env.API_URL + 'struttura', data);
  }

  updateStruttura(idStruttura: number ,data: any) {
    return this.http.put<any>(this.env.API_URL + 'struttura/', {id:idStruttura,...data});
  }


  associaRisorsa(data: any) {
    return this.http.post<any>(this.env.API_URL + 'struttura/associaRisorsa', data);
  }

  deleteStruttura(idStruttura: number | undefined) {
    if (!idStruttura) {
      idStruttura=0;
    }
    return this.http.delete<any>(this.env.API_URL + 'struttura/' + encodeURIComponent(idStruttura));
  }


  rimuoviRisorsa(id: number | undefined) {
    return this.http.delete<any>(this.env.API_URL + 'struttura/rimuoviRisorsa/' + id);

  }

}
