import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models/user';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { CruscottoService, PermissionService } from './_services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'performpa';

  dropdownHidden = true;

  anni: string[] = [];

  currentUser: User | undefined;

  userSubscription: Subscription | undefined;

  @ViewChild('dropdown') dropdown: ElementRef | undefined;

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private cruscottoService: CruscottoService,
    public permissionService: PermissionService
  ) { }

  ngOnInit(): void {

    this.userSubscription = this.authenticationService.currentUser?.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser?.operator) {
        this.cruscottoService.getAnniList(this.currentUser?.operator?.idEnte).subscribe(result => {
          this.anni=result;
        });
          this.dropdownHidden=true;
        if (this.currentUser) {

        }
      }
      //if (this.currentUser && !this.currentUser.operator) {
      //  this.logout();
      //}

    });

  }

  onClick(event:any) {
    if (this.dropdown && !this.dropdown.nativeElement.contains(event.target)) {
      this.dropdownHidden=true;
    }
   }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }


  isActive(name: string) {
    if (name === '/' && this.router.url === '/') {
      return true;
    }
    return (this.router.url.substr(1, name.length) === name);
  }

  cambiaAnno(anno:number) {
    this.permissionService.cambiaAnno(anno, this.currentUser);
    location.reload();
  }
}



